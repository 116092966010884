import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Tickets from "./components/Tickets";



function App() {
    // const [refreshApp, setRefreshApp] = useState(0);

  return (
      <div>
        <Router>
            {/*<div className="App">*/}
                {/*<header>

                    <h1>Mes tickets de caisse</h1>

                    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to={"/Tickets"}>Ticket Mongo</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/tickets"}>Tickets List</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </header>*/}

                {/*<Tickets/>*/}

                <div className="container m-auto">
                    <div className="row">
                        <div className="flex flex-col justify-center">

                            <Switch>
                                <Route path="/Tickets/:id" component={Tickets} />
                            </Switch>

                        </div>
                    </div>
                </div>
        </Router>
    </div>
  );
}

export default App;