import React, {useState} from "react";
import ModalSendByMail2 from "./ModalSendByMail2";

const SendByMail = ({ idTicket }) => {
    const [show, setShow] = useState(false);
    /* const ref = useRef(null);

    useEffect(() => {
        if (show) {
            // document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('click', handleClickOutside, false);
        } else {
            // document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('click', handleClickOutside, false);
        }

        // returned function will be called on component unmount
        return () => {
            // document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('click', handleClickOutside, false);
        }
    }, [show]);



   const handleClickOutside = e => {
        if (ref.current.contains(e.target)) {
            // inside click
            //notification status
            // setShow(true);
            console.log('Clicked inside');
            return;
        }
        // outside click
        setShow(false);
    };*/

    const showModal = () => {
        setShow(true);
    };

    const hideModal = () => {
        setShow(false);
    };



    return (
        <div className="mt-5 flex justify-center">

            <ModalSendByMail2
                show={show}
                handleClose={hideModal}
                idTicket={idTicket.id}
                /*ref={ref}*//>

            {/*Mail*/}
            <span className="sm:block ml-3">
                    <button type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={showModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </button>
                </span>
        </div>
    );
}

/*class SendByMail extends Component {
    constructor() {
        super();
        this.state = {
            show: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    render () {
        return (
            <div className="mt-5 flex justify-center">

                <ModalSendByMail
                    show={this.state.show}
                    handleClose={this.hideModal}/>

                {/!*Mail*!/}
                <span className="sm:block ml-3">
                    <button type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={this.showModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </button>
                </span>
            </div>
        );

    }
}*/

export default SendByMail;