import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import './Tickets.css';
// useParam permet de récupérer l'id passer en paramètre dans l'URL
import { useParams } from "react-router-dom";

import DownloadTicket from "./DownloadTicket";
// import DownloadTicketClass from "./DownloadTicketClass";
import SendByMail from "./SendByMail";
import DownloadOnTheAppleStore from "../images/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg";
// import DownloadOnTheGooglePlay from "../images/Google-play-badge.png";


// forwardRef
const Tickets = React.forwardRef((props,ref) => {

    const { REACT_APP_URL } = process.env;
    const param = useParams();
    const url = `${REACT_APP_URL}/WebServiceWrapper/GetTicket.php?id=${param.id}`;
    // const countUrl = `${REACT_APP_URL}/WebServiceWrapper/counter.php`;

    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // Asynchrone
    useEffect(() => {

        // Abort data fetching in effect hook
        let didCancel = false;

        const fetchData = async () => {
            setIsLoading(true);
            setIsError(false);

            try {
                const result = await axios(url);

                if (!didCancel) {
                    // console.log(result);
                    const ticketContentParse = await JSON.parse(result.data.result[0].TicketContent);
                    console.log("ticketContentParse",ticketContentParse);

                    setTickets(ticketContentParse);
                    setIsLoading(false);
                }

            } catch (error) {
                if (!didCancel) {
                    setIsError(true);
                }
            }
        };
        fetchData();

        return () => {
            didCancel = true;
        }
    }, [url]); // An empty array means the callback will only execute once // The callback will execute every time url changes.

    return (
        <Fragment>
            <div className="flex justify-center my-4">
                {/*<a className="google-badge mr-10" target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.spotify.music&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/>
                        <img alt='Disponible sur Google Play' src={ DownloadOnTheGooglePlay }/>
                    </a>*/}

                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/fr/app/mes-tickets-de-caisse/id1573554729">
                    <img alt='Disponible sur Google Play' src={ DownloadOnTheAppleStore }/>
                </a>
            </div>

            {/*<div className="border border-blue-cyan-light mx-7 sm:mx-auto my-4 px-5 py-4 rounded-md text-grey-dark">
                <div className="w-full flex flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Votre ticket reste uniquement disponible durant 30 jours, pensez à le télécharger.
                </div>
            </div>*/}

            <div className="flex flex-col justify-center">

                { isError && <div>Un problème est survenu ...</div>}

                { isLoading ? (
                    <div>Loading ...</div>
                ) : (

                    <Fragment>
                        <div className="flex justify-center">

                            <DownloadTicket
                                ticket={tickets}
                                idTicket={param}
                            />

                            <SendByMail idTicket={param} />
                        </div>

                        {/*<PrintTicket componentRef={componentRef}/>*/}
                        {/*<PrintTicket/>*/}

                        { tickets && tickets.LignesTickets ? (
                            <ul className="ticket flex flex-col my-12 px-4 py-10 serrated shadow-2xl min-w-max max-w-min mx-auto" {...props} ref={ref}>

                                {tickets.LignesTickets.map((line, index) => {

                                    let lineTicket = line.ContenuTicket.replace(/\s/g, "\u00a0");

                                    if (lineTicket.includes("ø")) {
                                        let degreeSymbolLine = lineTicket.replace(/ø/g, "°");
                                        return <li
                                            className={`${line.FlagDoubleHauteur === 1 && line.FlagDoubleLargeur === 1 ? 'double-width-and-height text-center' : 'text-xs'} ${line.FlagGras === true ? 'double-width text-center' : 'text-xs'}`}
                                            key={index}>
                                            { degreeSymbolLine }
                                        </li>

                                    } else if (lineTicket.substring(0) === "." ) {
                                        let dotAlone = lineTicket.substring(0);
                                        let dotAloneLine = dotAlone.replace(/\./g, "\u00a0");

                                        return <li
                                            className={` text-center ${line.FlagDoubleHauteur === 1 && line.FlagDoubleLargeur === 1 ? 'double-width-and-height' : 'text-xs'} ${line.FlagGras === true ? 'double-width' : 'text-xs'}`}
                                            key={index}>
                                            { dotAloneLine }
                                        </li>
                                    } else {
                                        return <li
                                            className={`${line.FlagDoubleHauteur === 1 && line.FlagDoubleLargeur === 1 ? 'double-width-and-height text-center' : 'text-xs'} ${line.FlagGras === true ? 'double-width text-center' : 'text-xs'}`}
                                            key={index}>
                                            { lineTicket }
                                        </li>
                                    }
                                })}
                            </ul>
                        ) : (
                            <p>Un problème est survenu lors de la récupération des données</p>
                        )}


                    </Fragment>
                )}

            </div>

            {/*<div className="flex justify-center mb-12">
                    // <a className="google-badge mr-10" target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.spotify.music&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    //     <img alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/>
                    //     <img alt='Disponible sur Google Play' src={ DownloadOnTheGooglePlay }/>
                    // </a>

                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/fr/app/mes-tickets-de-caisse/id1573554729">
                    <img alt='Disponible sur Google Play' src={ DownloadOnTheAppleStore }/>
                </a>
            </div>*/}

            <div className="border border-blue-cyan-light mx-7 sm:mx-auto mb-12 px-5 py-4 rounded-md text-grey-dark">
                <div className="w-full flex flex-col items-center">
                    {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>*/}
                    Votre ticket reste uniquement disponible durant 30 jours, pensez à le télécharger.
                </div>
            </div>

        </Fragment>
    );
});

/*const Tickets = () => {

    // const componentRef = useRef(null);
    const param = useParams();
    const { REACT_APP_URL } = process.env;
    const url = `${REACT_APP_URL}/WebServiceWrapper/GetTicket.php?id=${param.id}`;
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // Asynchrone
    useEffect(() => {

        // Abort data fetching in effect hook
        let didCancel = false;

        const fetchData = async () => {
            setIsLoading(true);
            setIsError(false);

            try {
                const result = await axios(url);

                if (!didCancel) {
                    const ticketContentParse = await JSON.parse(result.data.result[0].TicketContent);
                    console.log("ticketContentParse",ticketContentParse);

                    setTickets(ticketContentParse);
                    setIsLoading(false);
                }

            } catch (error) {
                if (!didCancel) {
                    setIsError(true);
                }
            }
        };
        fetchData();

        return () => {
            didCancel = true;
        }
    }, [url]); // An empty array means the callback will only execute once // The callback will execute every time url changes.


    // Not asynchrone
    /!*useEffect(() => {

        axios.get("http://127.0.0.1/mesticketsdecaisse/WebServiceWrapper/GetTicket.php", {
            params: {
                id: param.id,
                // id: '00A07A02000000A3'
            }
        })

            .then(function (response) {
                if (response.data.response === "success") {
                    console.log("response.data.result :")
                    console.log(response.data.result);

                    const ticketContentParse = JSON.parse(response.data.result[0].TicketContent);
                    console.log("ticketContentParse :");
                    console.log(ticketContentParse);

                    console.log("ticketContentParse.LignesTickets[0].ContenuTicket :")
                    console.log(ticketContentParse.LignesTickets[0].ContenuTicket);

                    // setTickets(response.data.result);
                    setTickets(ticketContentParse);
                }
            })

            .catch(function (error) {
                console.log(error);
            });
    }, []);*!/



    return (
        <Fragment>

            <div className="border border-blue-cyan mx-auto my-4 px-5 py-4 rounded-md text-grey-dark">
                <div className="w-full flex flex-col items-center">
                    {/!*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>*!/}
                    Votre ticket reste uniquement disponible durant 30 jours, pensez à le télécharger.
                </div>
            </div>

            <div className="flex flex-col justify-center">

                { isError && <div>Un problème est survenu ...</div>}

                { isLoading ? (
                    <div>Loading ...</div>
                ) : (

                    <Fragment>

                        <DownloadTicket
                            ticket={tickets}
                            idTicket={param}/>

                        {/!*<PrintTicket componentRef={componentRef}/>*!/}
                        {/!*<PrintTicket />*!/}

                        { tickets && tickets.LignesTickets ? (
                            <ul className="ticket flex flex-col my-12 px-4 py-10 serrated shadow-2xl min-w-max max-w-min mx-auto">
                                {tickets.LignesTickets.map((line, index) => {

                                    // return <li style={line.FlagDoubleHauteur == 1 ? { fontSize: "18px" } : { fontSize: "12px" }} key={index}>{line.ContenuTicket}</li>
                                    return <li
                                        className={` text-center ${line.FlagDoubleHauteur === 1 && line.FlagDoubleLargeur === 1 ? 'double-width-and-height' : 'text-xs'} ${line.FlagGras === true ? 'double-width' : 'text-xs'}`}
                                        key={index}>{ line.ContenuTicket.replace(/\s/g, "\u00a0") }</li>
                                    }
                                )}
                            </ul>
                        ) : (
                            <p>Un problème est survenu lors de la récupération des données</p>
                        )}

                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}*/

export default Tickets;

