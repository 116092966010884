import React, {useState} from "react";
import axios from "axios";
// import useForm from "./useForm";

const ModalSendByMail2 = ({ handleClose, show, children, idTicket }) => {

    const showHideClassName = show ? "min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-gray-300 bg-opacity-25 block mx-3" : "fmin-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-gray-300 bg-opacity-25 hidden";

    const { REACT_APP_URL } = process.env;

    const count = {
        objectIdMongoDB: "60e71d3a0f6d4a5dc5622825",
        fieldName: 'Mail'
    }

    const fetchData = () => {

        axios.all([
            axios({
                method: "post",
                url: `${REACT_APP_URL}/WebServiceWrapper/sendEmail.php`,
                data: formData,
            }),
            axios({
                method: "post",
                url: `${REACT_APP_URL}/WebServiceWrapper/counter.php`,
                data: JSON.stringify(count),
            }),
        ])
            // .then(axios.spread((formResponse, isClickedResponse) => {
            // console.log(formResponse.data);
            // console.log(isClickedResponse.data.objectIdMongoDB);
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Création d'un hook personalisé "useForm" en passant en paramètre l'état initial et la fonction onSubmit qui prend en charge le "formData"
    const useForm = (initialState = {}, validations, onSubmit) => {

        //Gère l'état du formulaire
        const [formData, setFormData] = useState(initialState);
        const [errors, setErrors] = useState({});

        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }

        const handleSubmit = (e) => {
            // Empêche le rechargement de la page
            e.preventDefault();

            if (validations) {
                let valid = true;
                const newErrors = {};

                for (const key in validations) {
                    // value of the field we're validating
                    const value = formData[key];

                    // the matching validation rule for this key
                    const validation = validations[key];
                    if (validation?.required?.value && !value) {
                        valid = false;
                        newErrors[key] = validation?.required?.message;
                    }

                    const pattern = validation?.pattern;
                    if (pattern?.value && !RegExp(pattern.value).test(value)) {
                        valid = false;
                        newErrors[key] = pattern.message;
                    }
                }

                if (!valid) {
                    setErrors(newErrors);
                    console.log(`Error : ${errors}`);
                    return;
                }
            }

            setErrors({});

            if (onSubmit) {
                // La fonction onSubmit prend en charge le formData
                onSubmit?.(formData);
            }


            // La fonction onSubmit prend en charge le formData
            // onSubmit?.(formData);
        }
        return { formData, handleChange, handleSubmit, errors };
    };

    const { formData, handleChange, handleSubmit, errors } = useForm(
        {
            email: "",
            id: idTicket,
        },
        {
            email: {
                required: {
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "L'email est incorrect.",
                    },
                },
            },
        },
        (formData) => {
            console.dir(formData);
            handleClose();
        }
    );

    const { email } = formData;

    return (
        <div
            className={showHideClassName}
            id="modal-id"
            /*ref={ref}*/>{children}

            <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                <div className="">
                    <div className="text-center p-5 flex-auto justify-center">

                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="w-16 h-16 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>

                        <h2 className="text-xl font-bold py-4 ">Envoyer son ticket par email</h2>
                        {/*<p className="text-sm text-gray-500 px-8">Entrez votre adresse email pour recevoir votre ticket de caisse</p>*/}

                        <form className="mt-8 space-y-6" onSubmit={handleSubmit} method="POST">

                            <div className="relative">

                                <div className="absolute right-0 mt-4">

                                    { errors.email
                                        ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    }

                                </div>

                                {/*<div className={`block ${focused ? 'focus:border-blue-royal transform-gpu scale-y-150' : ''}`}>*/}
                                <label className="text-sm font-bold text-gray-700 tracking-wide">Email</label>
                                <input
                                    className=" w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-blue-cyan-light"
                                    type="email"
                                    name="email"
                                    placeholder="mail@gmail.com"
                                    onChange={handleChange}
                                    value={email}
                                    required
                                    /*onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}*/
                                    /*ref={register({
                                        required: "Entrez votre email",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Entrez une adresse email valide",
                                        },
                                    })}*/
                                />
                                { errors.email && <span className="text-xs text-red-700">{ errors.email.message }</span> }
                                {/*</div>*/}


                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center bg-blue-cyan-light text-gray-100 p-4  rounded-full tracking-wide font-semibold
                                    focus:outline-none focus:shadow-outline hover:bg-blue-cyan-dark shadow-lg cursor-pointer transition ease-in duration-300"
                                    onClick={ fetchData }>
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="p-3  mt-2 text-center space-x-4 md:block">
                        <button
                            className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
                            onClick={handleClose}>
                            Annuler
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSendByMail2;

