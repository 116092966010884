import React from "react";
import axios from "axios";

const DownloadTicket = ({  idTicket, ticket }) => {

    const { REACT_APP_URL } = process.env;
    const count = {
        objectIdMongoDB: "60dc8d9096903539460cbc21",
        fieldName: 'Download'
    }

    const fetchData = () => {
        axios.all([
             axios({
                method: "get",
                url: `${REACT_APP_URL}/WebServiceWrapper/download.php?id=${idTicket.id}`,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                    'Accept': 'application/pdf'
                }
        }),
            axios({
                method: "post",
                url: `${REACT_APP_URL}/WebServiceWrapper/counter.php`,
                data: JSON.stringify(count)
            })

        ]).then(axios.spread((data1, data2) => {
            console.log('data1', data1, 'data2', data2);
            console.log(idTicket.id)

            const file = new Blob(
                [data1.data],
                {type: 'application/pdf'});

            const fileURL = document.createElement("a");
            fileURL.href = URL.createObjectURL(file);

            const nameStore = ticket.NomCommunMagasin;
            const dateTicket = ticket.DateTicket;
            const hourTicket = ticket.HeureTicket;

            fileURL.download = `Ticket-${nameStore}-${dateTicket}-${hourTicket}.pdf`;
            document.body.appendChild(fileURL);
            fileURL.click();

        })).catch((error) => { console.log(error) });
    }

    return (
        <div className="mt-5 flex justify-center">

            {/*Télécharger*/}
            <span className="sm:ml-3">
                  <button type="button"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={ fetchData }>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                  </button>
            </span>
        </div>
    );
}

export default DownloadTicket;